<script>
import Layout from "../../layouts/main"
import { onMounted, reactive, ref, watch } from "vue"
import { getCustomers } from "./api"
import { formatDatetimeBR } from "../../../utils/date"
import { debounce } from "lodash"
import AppPagination from "../../../components/app-pagination.vue"
import SelectContest from '@/components/select-contest'
import { Toasts } from "../../../utils/toast-alerts"

export default {
  setup() {
    const loading = ref(false)
    const clientList = ref()
    const totalClients = ref(0)
    const page = ref(1)
    const pages = ref(0);
    const filterClient = reactive({
      search: "",
      limit: 10,
      page: 1,
    })
    const contestId = ref(null)

    const handleSearch = debounce(async (e) => {
      filterClient.title = e.target.value
      await getClients()
    }, 500)

    async function getClients() {
      loading.value = true;
      try {
        const response = await getCustomers( {
          search: filterClient.search,
          contestId: contestId?.value?.value,
          page: page.value
        });
        clientList.value = response.data
        pages.value = response.last_page
        totalClients.value = response.total

      }catch (error) {
        Toasts('error', 'Falha ao carregar os clientes')
      } finally {
        loading.value = false;
      }
    }

    watch([
      contestId
    ], () => {
      getClients()
    })

    onMounted(() => {
      getClients()
    })

    return {
      loading,
      clientList,
      getClients,
      formatDatetimeBR,
      onMounted,
      filterClient,
      handleSearch,
      page,
      pages,
      totalClients,
      contestId
    }
  },
  components: {
    AppPagination,
    SelectContest,
    Layout,
  },
};

</script>

<template>
  <Layout :pagetitle="'Clientes Grid'">
    <div class="card">
      <div class="row align-items-center">
        <div class="col">
          <div>
            <h5 class="card-header text-uppercase">
              Clientes <span class="text-muted fw-normal ms-2">({{ totalClients }})</span>
            </h5>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card-header
              d-flex
              flex-wrap
              align-items-center
              justify-content-end
              gap-2
              mb-3
            "
          >
            <div>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/clients"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="List"
                    ><i class="bx bx-list-ul"></i
                  ></router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link active"
                    to="/clients-grid"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Grid"
                    ><i class="bx bx-grid-alt"></i
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <h5 class="card-header pb-3">
            <div class="position-relative">
              <i
                class="search-icon"
                data-eva="search-outline"
                data-eva-height="26"
                data-eva-width="26"
              >
              </i>
              <input
                v-model="filterClient.search"
                type="text"
                style="min-width: 350px; max-width: 500px;"
                class="form-control"
                placeholder="Pesquisar clientes por nome ou WhatsApp"
                @input="handleSearch"
              >
            </div>
          </h5>
        </div>
        <div class="col">
          <h5 class="card-header pb-4">
            <div class="position-relative">
              <SelectContest
                placeholder="Pesquisar por sorteio"
                v-model="contestId"
              />
            </div>
          </h5>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row" >
      <div
          class="col-xl-3 col-sm-6"
          v-for="client in clientList"
          :key="client.id">
        <div class="card" >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-1 ms-3">
                <h5 class="font-size-16 mb-1">
                  <a href="#" class="text-dark">{{ client.name }}</a>
                </h5>
                <span
                    class="badge badge-soft-danger mb-0"
                    v-if="client.blocked === true"
                    style="color: red"
                >Bloqueado
                </span>
                <span
                    class="badge badge-soft-success mb-0"
                    v-else
                    style="color: green"
                >Ativo
                </span>
              </div>
            </div>
            <div class="mt-3 pt-1">
              <p class="text-muted mb-0">
                <i
                  class="
                    mdi mdi-phone
                    font-size-15
                    align-middle
                    pe-2
                    text-primary
                  "
                ></i>
                {{ client.whatsapp }}
              </p>
              <p class="text-muted mb-0 mt-2">
                <i
                  class="
                    mdi mdi-calendar-check
                    font-size-15
                    align-middle
                    pe-2
                    text-primary
                  "
                ></i>
                {{ formatDatetimeBR(client.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <app-pagination
        class="pagination"
        v-model="page"
        :pages="pages"
        :range-size="1"
        @update:modelValue="getClients"
      ></app-pagination>
    </div>
    <!-- end row -->
  </Layout>
</template>