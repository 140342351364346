import { axios } from '../../../axios'
import fileDownload from 'js-file-download'

export const getCustomers = (params = {}) => {
  return axios.get('/customers', { params }).then(({ data }) => data)
}

export const exportCustomersList = async (params = {}) => {
  const fileName = `lista_clientes.csv`

  const response = await axios.get(`customers/export-csv`, { params }, { responseType: 'blob' })

  const fileNameHeader = "x-suggested-filename"
  const suggestedFileName = response.headers[fileNameHeader]
  const effectiveFileName = suggestedFileName || fileName
  fileDownload(response.data, effectiveFileName)
}